import { useRef, useState } from 'react';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import { memo } from 'react';
// import MDInput from "components/MDInput";

const libraries = ['places'];
const GooglePlacePicker = (props) => {
	const { label, name, value, setFormData, formData, handleChange, id } = props;
	const inputRef = useRef();
	const [isPlaceSelected, setIsPlaceSelected] = useState(false);

	const handlePlaceChanged = () => {
		const [place] = inputRef.current.getPlaces();
		if (place) {
			setIsPlaceSelected(true);
			setFormData({
				...formData,
				[name]: place?.formatted_address,
				googleAddress: place,
			});
		}
	};

	const handleBlur = () => {
		if (!isPlaceSelected) {
			setFormData({
				...formData,
				[name]: '',
				googleAddress: null,
			});
		}
		setIsPlaceSelected(false);
	};

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} libraries={libraries}>
			<StandaloneSearchBox
				onLoad={(ref) => (inputRef.current = ref)}
				onPlacesChanged={handlePlaceChanged}
			>
				<input
					type='text'
					id={id}
					value={value}
					className='form-control addVehicleSelect'
					autoComplete='off'
					onChange={handleChange}
					name={name}
					placeholder={label}
					onBlur={handleBlur}
				/>
			</StandaloneSearchBox>
		</LoadScript>
	);
};

export default memo(GooglePlacePicker);
