import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import * as api from '../../../api';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../globalState/action-creators';
import Select from '../../../components/bootstrap/forms/Select';
import { GUEST_TYPE } from '../../../constants';
import moment from 'moment';
import DatePicker from 'react-date-picker';

const EditGuest = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');
	const today = new Date();

	const [unitNumberData, setUnitNumberData] = useState([]);
	const [selectedUnit, setSelectedUnit] = useState('');

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchGuest();
		fetchAllUnitNumbers();

		// eslint-disable-next-line
	}, []);

	const fetchAllUnitNumbers = async () => {
		try {
			const { data } = await api.getAllUnitNumbers();
			if (data?.success) {
				const unitData = data.data.map((item, index) => {
					return {
						...item,
						value: item?._id,
						text: item?.unitNumber,
						id: item?._id,
						accessDuration: item?.guestAccessDuration,
						durationType: item?.guestAccessDurationType,
					};
				});
				setUnitNumberData(unitData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const [minMoveIndate, setMinMoveIndate] = useState(null);
	const [nameError, setNameError] = useState(false);
	const [guestTypeError, setGuestTypeError] = useState(false);
	const [licensePlateError, setLicensePlateError] = useState(false);
	const [unitsError, setUnitsError] = useState(false);
	const [moveInDateError, setMoveInDateError] = useState(false);
	const [startTimeError, setStartTimeError] = useState(false);
	const [makeError, setMakeError] = useState(false);
	const [modelError, setModelError] = useState(false);

	const [formData, setFormData] = useState({
		guestName: '',
		licensePlate: '',
		units: '',
		unitId: '',
		guestType: '',
		MoveInDate: '',
		MoveOutDate: '',
		startTime: '',
		endTime: '',
		model: '',
		make: '',
	});

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'guestName') {
			setNameError(false);
		}
		if (name === 'licensePlate') {
			setLicensePlateError(false);
		}
		if (name === 'guestType') {
			setGuestTypeError(false);
		}
		if (name === 'make') {
			setMakeError(false);
		}
		if (name === 'model') {
			setModelError(false);
		}
		if (name === 'startTime') {
			setStartTimeError(false);
		}

		setFormData({ ...formData, [name]: value });
	};

	const fetchGuest = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getGuestById(id);
			if (data?.success) {
				setFormData((prevData) => ({
					...prevData,
					...data?.data,
				}));
				setMinMoveIndate(new Date(data?.data?.MoveInDate));
			} else {
				dispatch(setAlert(data?.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const updateGuest = async () => {
		dispatch(setLoading(true));
		try {
			let error = false;
			const reDigit = /^[0-9]*$/;
			if (_.get(formData, 'guestName', '')?.length <= 0) {
				error = true;
				setNameError(true);
			}
			if (
				_.get(formData, 'licensePlate', '')?.length <= 0
				// || !reDigit.test(_.get(formData, 'licensePlate', ''))
			) {
				error = true;
				setLicensePlateError(true);
			}
			if (_.get(formData, 'guestType', '')?.length <= 0) {
				error = true;
				setGuestTypeError(true);
			}
			if (_.get(formData, 'units', '')?.length <= 0) {
				error = true;
				setUnitsError(true);
			}
			if (_.get(formData, 'make', '')?.length <= 0) {
				error = true;
				setMakeError(true);
			}
			if (_.get(formData, 'model', '')?.length <= 0) {
				error = true;
				setModelError(true);
			}

			if (_.get(formData, 'MoveInDate', '')?.length <= 0) {
				error = true;
				setMoveInDateError(true);
			}

			if (_.get(formData, 'startTime', '')?.length <= 0) {
				error = true;
				setStartTimeError(true);
			}

			if (!error) {
				const { data } = await api.updateGuest(formData);
				if (data?.success) {
					navigate('/guests');
					dispatch(setAlert(data?.message, 'Success'));
				} else {
					dispatch(setAlert(data?.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	useEffect(() => {
		if (selectedUnit && formData?.MoveInDate && formData?.startTime) {
			const { guestAccessDuration, guestAccessDurationType } = selectedUnit;

			const formattedDate = formData?.MoveInDate
				? moment(formData?.MoveInDate).format('MM/DD/yyyy')
				: '';

			let calculatedEndDate;
			if (guestAccessDuration && guestAccessDurationType) {
				// Combine MoveInDate and startTime
				const startDateTime = moment(
					`${formattedDate} ${formData.startTime}`,
					'MM/DD/yyyy HH:mm',
				);
				calculatedEndDate = startDateTime.add(
					Number(guestAccessDuration),
					guestAccessDurationType.toLowerCase(),
				);
			}

			setFormData((prevFormData) => ({
				...prevFormData,
				MoveOutDate: calculatedEndDate ? calculatedEndDate.format('MM/DD/yyyy') : '',
				endTime: calculatedEndDate ? calculatedEndDate.format('HH:mm') : '',
			}));
		}
	}, [selectedUnit, formData?.MoveInDate, formData?.startTime]);

	return (
		<PageWrapper title='Update Guest'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Plus' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Edit Guest
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className=''>
										<div className='create-vehicle-wrapper add_vehicle'>
											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='guestName'
														className='form-control addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														value={formData?.guestName}
														onKeyDown={(event) => {
															if (!/^[a-zA-Z\s]+$/.test(event.key)) {
																event.preventDefault();
															}
														}}
														name='guestName'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{nameError ? 'Name required!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Registration Type
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														id='guestType'
														name='guestType'
														value={formData?.guestType}
														onChange={(e) => {
															onChange(e);
														}}
														list={GUEST_TYPE}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{guestTypeError ? 'Select guest Type.' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														License Plate{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='licenePlate'
														value={formData?.licensePlate}
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='licensePlate'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															const key = e.key;
															const isSpecialCharacter =
																/[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;

															if (
																key === ' ' ||
																(isSpecialCharacter.test(key) &&
																	key !== '-')
															) {
																e.preventDefault();
															}
														}}
													/>
												</div>
												<span className='text-danger danger-msg'>
													{licensePlateError
														? 'License Plate required!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Make{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														value={formData?.make}
														onChange={(e) => {
															onChange(e);
														}}
														name='make'
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{makeError && 'Please enter a make!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Model
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='model'
														value={formData?.model}
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															if (e.key === ' ') {
																e.preventDefault();
															}
														}}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{modelError && 'Please enter a model!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Unit{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														id='units'
														name='units'
														disabled
														value={formData?.unitId}
														onChange={(e) => {
															const selectedValue = e.target.value;
															const selectedItem =
																unitNumberData.find(
																	(item) =>
																		item.id === selectedValue,
																);

															setFormData((prevFormData) => ({
																...prevFormData,
																unitId: selectedValue || '',
																units:
																	selectedItem?.unitNumber || '',
															}));

															setUnitsError(false);
															setSelectedUnit(selectedItem);
														}}
														list={
															unitNumberData?.length > 0
																? unitNumberData
																: [
																		{
																			value: '',
																			text: 'No Space Available',
																		},
																  ]
														}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{unitsError ? 'Unit required!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Arrival Date{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<DatePicker
														className='form-control addVehicleSelect input_feild'
														clearIcon={null}
														name='MoveInDate'
														disabled={minMoveIndate < today}
														onChange={(e) => {
															setMoveInDateError(false);
															const formattedDate = e
																? moment(e).format('MM/DD/yyyy')
																: '';
															setFormData({
																...formData,
																MoveInDate: formattedDate,
															});
														}}
														format='MM/dd/yyyy'
														value={
															formData?.MoveInDate &&
															formData?.MoveInDate?.length > 0
																? new Date(formData?.MoveInDate)
																: ''
														}
														minDate={
															minMoveIndate < today
																? minMoveIndate
																: today
														}
													/>
												</div>

												<span className='text-danger danger-msg'>
													{moveInDateError
														? 'Arrival date required!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<p className=' lable_spacing'>
													Arrival Time{' '}
													<span className='text-danger fw-bold'>*</span>
												</p>
												<input
													class='form-control addVehicleSelect editratelable'
													id='startTime'
													type='time'
													required=''
													placeholder='Enter start time...'
													name='startTime'
													value={formData?.startTime}
													onChange={onChange}
												/>

												<span className='text-danger danger-msg'>
													{startTimeError ? 'Arrival time required!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														End Date{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<DatePicker
														className='form-control addVehicleSelect input_feild'
														clearIcon={null}
														name='MoveOutDate'
														disabled
														format='MM/dd/yyyy'
														value={
															formData?.MoveOutDate &&
															formData?.MoveOutDate?.length > 0
																? new Date(formData?.MoveOutDate)
																: ''
														}
														minDate={
															formData?.MoveInDate
																? new Date(formData?.MoveInDate)
																: today
														}
													/>
												</div>{' '}
											</div>

											<div className='card-info-item card-inner'>
												<p className=' lable_spacing'>
													End Time{' '}
													<span className='text-danger fw-bold'>*</span>
												</p>
												<input
													class='form-control addVehicleSelect editratelable'
													id='endTime'
													type='time'
													disabled
													required=''
													placeholder='Enter start time...'
													name='endTime'
													value={formData?.endTime}
													onChange={onChange}
												/>
											</div>
										</div>
									</div>
								</div>
								<hr></hr>
								<Button className='mx-2 mt-3' color='dark' onClick={updateGuest}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/guests')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default EditGuest;
