import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import * as api from '../../../api';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../globalState/action-creators';
import './media.css';
import Select from '../../../components/bootstrap/forms/Select';
import { HoldVehicleType } from '../../../constants';
import 'antd/dist/antd.css';
import DatePicker from 'react-date-picker';
import moment from 'moment';

const EditHoldVehicle = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const today = new Date();

	const token = sessionStorage.getItem('token');

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchVehicle();
		fetchResidentUnitNumber();
		// eslint-disable-next-line
	}, []);

	const [searchData, setSearchData] = useState({
		total: 0,
		name: [],
	});

	const [minMoveIndate, setMinMoveIndate] = useState(null);
	const [nameError, setNameError] = useState(false);
	const [licensePlateError, setLicensePlateError] = useState(false);
	const [makeError, setMakeError] = useState(false);
	const [modelError, setModelError] = useState(false);
	const [vehicleTypeError, setVehicleTypeError] = useState(false);
	const [dropdownSelected, setDropdownSelected] = useState(false);
	const [selectNameError, setSelectNameError] = useState(false);
	const [moveInDateError, setMoveInDateError] = useState(false);
	const [moveOutDateError, setMoveOutDateError] = useState(false);
	const [startTimeError, setStartTimeError] = useState(false);

	const [unitNumber, setUnitNumber] = useState('');
	const [residentType, setResidentType] = useState('');

	const [formData, setFormData] = useState({
		name: '',
		licensePlate: '',
		make: '',
		model: '',
		vehicleType: '',
		MoveInDate: '',
		MoveOutDate: '',
		startTime: '',
		endTime: '',
	});

	const handleSearch = async (name) => {
		try {
			const { data } = await api.searchNames(name);
			if (data.success) {
				setSearchData({
					total: data.total || 0,
					name: data?.data ? data?.data : [],
				});
			}
		} catch (error) {
			setAlert(error.message, 'error');
		}
	};

	const handleAutoCompleteInput = (value) => {
		setTimeout(() => {
			handleSearch(value);
		}, 600);
	};

	// GET unit DATA

	const fetchResidentUnitNumber = async () => {
		try {
			const { data } = await api.getResidentUnitNumber();
			if (data?.success) {
				setUnitNumber(data?.data?.unitId);
				setResidentType(data?.data?.residentType);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') {
			setNameError(false);
		}
		if (name === 'licensePlate') {
			setLicensePlateError(false);
		}
		if (name === 'make') {
			setMakeError(false);
		}
		if (name === 'model') {
			setModelError(false);
		}
		if (name === 'vehicleType') {
			setVehicleTypeError(false);
		}
		if (name === 'MoveInDate') {
			setMoveInDateError(false);
		}
		if (name === 'startTime') {
			setStartTimeError(false);
		}

		if (
			name === 'vehicleType' &&
			(value === 'Homeowner Resident' || value === 'Tenant Resident')
		) {
			setFormData({
				...formData,
				[name]: value,
				MoveInDate: null,
				MoveOutDate: null,
				startTime: null,
				endTime: null,
			});

			setMoveInDateError(false);
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	// GET Vehicle DATA
	const fetchVehicle = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getVehicleById(id);
			if (data?.success) {
				setDropdownSelected(true);
				setFormData((prevData) => ({
					...prevData,
					...data?.data,
				}));
				setMinMoveIndate(new Date(data?.data?.MoveInDate));
			} else {
				dispatch(setAlert(data?.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const updateVehicle = async () => {
		dispatch(setLoading(true));
		try {
			let error;

			if (_.get(formData, 'name', '')?.length <= 0) {
				error = true;
				setNameError(true);
			}
			if (
				_.get(formData, 'licensePlate', '')?.length <= 0
				// !reDigit.test(_.get(formData, 'licensePlate', ''))
			) {
				error = true;
				setLicensePlateError(true);
			}
			if (_.get(formData, 'make', '')?.length <= 0) {
				error = true;
				setMakeError(true);
			}
			if (_.get(formData, 'model', '')?.length <= 0) {
				error = true;
				setModelError(true);
			}
			if (_.get(formData, 'vehicleType', '')?.length <= 0) {
				error = true;
				setVehicleTypeError(true);
			}
			if (
				formData?.vehicleType === 'Unit Guest' ||
				formData?.vehicleType === 'Management Guest'
			) {
				if (_.get(formData, 'MoveInDate', '')?.length <= 0) {
					error = true;
					setMoveInDateError(true);
				}

				if (_.get(formData, 'startTime', '')?.length <= 0) {
					error = true;
					setStartTimeError(true);
				}
			}
			if (!error) {
				const vehicleFormData = { ...formData, unitId: formData?.unitId?._id };

				const { data } = await api.updateVehicle(vehicleFormData);
				if (data.success) {
					navigate('/vehicles');
					dispatch(setAlert(data.message, 'Success'));
				} else {
					dispatch(setAlert(data.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const handleAutoCompleteSelect = (value) => {
		setDropdownSelected(true);
		setSelectNameError(false);
		setFormData({
			...formData,
			name: value,
		});
	};

	const handleAutoCompleteChange = (value) => {
		setNameError(false);
		setFormData({
			...formData,
			name: value,
		});
		setDropdownSelected(false);
		setSelectNameError(false);
	};

	useEffect(() => {
		if (unitNumber && formData?.MoveInDate && formData?.startTime) {
			const { guestAccessDuration, guestAccessDurationType } = unitNumber;

			const formattedDate = formData?.MoveInDate
				? moment(formData?.MoveInDate).format('MM/DD/yyyy')
				: '';

			let calculatedEndDate;
			if (guestAccessDuration && guestAccessDurationType) {
				// Combine MoveInDate and startTime
				const startDateTime = moment(
					`${formattedDate} ${formData.startTime}`,
					'MM/DD/yyyy HH:mm',
				);
				calculatedEndDate = startDateTime.add(
					Number(guestAccessDuration),
					guestAccessDurationType.toLowerCase(),
				);
			}

			setFormData((prevFormData) => ({
				...prevFormData,
				MoveOutDate: calculatedEndDate ? calculatedEndDate.format('MM/DD/yyyy') : '',
				endTime: calculatedEndDate ? calculatedEndDate.format('HH:mm') : '',
			}));
		}
	}, [unitNumber, formData?.MoveInDate, formData?.startTime]);

	return (
		<PageWrapper title='Edit Vehicle'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Plus' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Update Vehicle
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='create_vehicle_inner'>
										{/* <div className='mx-2'>
											<p>
												<strong className='create-vehicle-heading'>
													HouseHold Features
												</strong>
											</p>
										</div> */}
										<div className='create-vehicle-wrapper add_vehicle'>
											{/* <div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Resident Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<AutoComplete
														onSearch={handleAutoCompleteInput}
														className='w-100 custom_class'
														value={formData?.name}
														onSelect={handleAutoCompleteSelect}
														onChange={handleAutoCompleteChange}
														onBlur={() => {
															!dropdownSelected &&
																setSelectNameError(true);
														}}
													>
														{searchData &&
															searchData?.name?.map((item) => (
																<AutoComplete.Option
																	key={item?._id}
																	value={item?.name}
																>
																	{item?.name}
																</AutoComplete.Option>
															))}
													</AutoComplete>
												</div>
												<span
													id='yearOfMakeSpan'
													className='text-danger danger-msg'
												>
													{nameError
														? 'Please enter a name'
														: !nameError &&
														  !dropdownSelected &&
														  selectNameError &&
														  formData?.name?.length > 0 &&
														  'Please select a name from options!'}
												</span>
											</div> */}

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='name'
														value={formData?.name}
														onChange={(e) => {
															onChange(e);
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{nameError && 'Please enter a name!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														License Plate{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='licensePlates'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='licensePlate'
														value={formData?.licensePlate}
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															const key = e.key;
															const isSpecialCharacter =
																/[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;

															if (
																key === ' ' ||
																(isSpecialCharacter.test(key) &&
																	key !== '-')
															) {
																e.preventDefault();
															}
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesSpan'
													className='text-danger danger-msg'
												>
													{licensePlateError &&
														'License Plate Number required!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Make{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='make'
														value={formData?.make}
														onChange={(e) => {
															onChange(e);
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{makeError && 'Please enter a make!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Type
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														disabled
														style={{
															boxShadow:
																'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
														}}
														id='vehicleType'
														name='vehicleType'
														value={formData?.vehicleType}
														onChange={(e) => onChange(e)}
														list={HoldVehicleType}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{vehicleTypeError &&
														'Please select vehicle type!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Model{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='model'
														value={formData?.model}
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															if (e.key === ' ') {
																e.preventDefault();
															}
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{modelError && 'Please enter a model!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Unit Number{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='unitNumber'
														value={formData?.unitId?.unitNumber}
														disabled
														style={styles.inputStyles}
													/>
												</div>
											</div>

											{(formData?.vehicleType === 'Unit Guest' ||
												formData?.vehicleType === 'Management Guest') && (
												<>
													<div className='card-info-item card-inner'>
														<div className='label'>
															<p className='mx-3 lable_spacing'>
																Arrival Date{' '}
																<span className='text-danger fw-bold'>
																	*
																</span>
															</p>
														</div>
														<div className='data'>
															<DatePicker
																className='form-control addVehicleSelect input_feild'
																clearIcon={null}
																disabled={minMoveIndate < today}
																name='MoveInDate'
																onChange={(e) => {
																	setMoveInDateError(false);
																	const formattedDate = e
																		? moment(e).format(
																				'MM/DD/yyyy',
																		  )
																		: '';
																	setFormData({
																		...formData,
																		MoveInDate: formattedDate,
																	});
																}}
																format='MM/dd/yyyy'
																value={
																	formData?.MoveInDate &&
																	formData?.MoveInDate?.length > 0
																		? new Date(
																				formData?.MoveInDate,
																		  )
																		: ''
																}
																minDate={
																	minMoveIndate < today
																		? minMoveIndate
																		: today
																}
															/>
														</div>

														<span className='text-danger danger-msg'>
															{moveInDateError
																? 'Arrival date required!'
																: ''}
														</span>
													</div>

													<div className='card-info-item card-inner'>
														<p className=' lable_spacing'>
															Arrival Time{' '}
															<span className='text-danger fw-bold'>
																*
															</span>
														</p>
														<input
															class='form-control addVehicleSelect editratelable'
															id='startTime'
															type='time'
															required=''
															placeholder='Enter start time...'
															name='startTime'
															value={formData?.startTime}
															onChange={onChange}
														/>

														<span className='text-danger danger-msg'>
															{startTimeError
																? 'Arrival time required!'
																: ''}
														</span>
													</div>

													<div className='card-info-item card-inner'>
														<div className='label'>
															<p className='mx-3 lable_spacing'>
																End Date{' '}
																<span className='text-danger fw-bold'>
																	*
																</span>
															</p>
														</div>
														<div className='data'>
															<DatePicker
																className='form-control addVehicleSelect input_feild'
																clearIcon={null}
																name='MoveOutDate'
																disabled
																format='MM/dd/yyyy'
																value={
																	formData?.MoveOutDate &&
																	formData?.MoveOutDate?.length >
																		0
																		? new Date(
																				formData?.MoveOutDate,
																		  )
																		: ''
																}
																minDate={
																	formData?.MoveInDate
																		? new Date(
																				formData?.MoveInDate,
																		  )
																		: today
																}
															/>
														</div>{' '}
													</div>

													<div className='card-info-item card-inner'>
														<p className=' lable_spacing'>
															End Time{' '}
															<span className='text-danger fw-bold'>
																*
															</span>
														</p>
														<input
															class='form-control addVehicleSelect editratelable'
															id='endTime'
															type='time'
															disabled
															required=''
															placeholder='Enter start time...'
															name='endTime'
															value={formData?.endTime}
															onChange={onChange}
														/>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<Button className='mx-2 mt-3' color='dark' onClick={updateVehicle}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/vehicles')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

const styles = {
	dateInput: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
		color: 'transparent',
	},
	onChangeColor: '#323232',
	inputStyles: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
	},
};

export default EditHoldVehicle;
