import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputMask from 'react-text-mask';
import * as api from '../../../../api';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';

import {
	setAlert,
	setLoading,
	setPartnerPreviewPage,
} from '../../../../globalState/action-creators';

const EditPartner = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');

	const [isEmailSent, setIsEmailSent] = useState(false);

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchPartner();
		// eslint-disable-next-line
	}, []);

	const fetchPartner = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getPartnerById(id);
			if (data?.success) {
				setFormData((prevData) => ({
					...prevData,
					...data?.data,
				}));
				setIsEmailSent(data?.data?.sendAdminWelcomeEmail);
			} else {
				dispatch(setAlert(data?.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const phoneNumberMask = [
		'(',
		/[1-9]/,
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	];
	const [refetch, setRefetch] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [contactNumberError, setContactNumberError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [adminEmailError, setAdminEmailError] = useState(false);
	const [partnerAddressError, setPartnerAddressError] = useState(false);
	const [totalSpaceError, setTotalSpaceError] = useState(false);
	const [lessTotalSpaceError, setLessTotalSpaceError] = useState(false);
	const [residentSpaceError, setResidentSpaceError] = useState(false);
	const [businessNameError, setBusinessNameError] = useState(false);
	const [guestSpaceError, setGuestSpaceError] = useState(false);

	const [formData, setFormData] = useState({
		partnerName: '',
		businessName: '',
		email: '',
		phoneNumber: '',
		adminEmail: '',
		partnerAddress: '',
		totalSpaces: '',
		residentSpaces: '',
		surplusSpaces: '',
		isAccessToRates: false,
		isAccessToRevenue: false,
		isAccessToReservation: false,
		sendAdminWelcomeEmail: false,
		partnerLogo: [],
		googleAddress: null,

	});

	const updatePartner = async () => {
		dispatch(setLoading(true));
		try {
			const regex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			let error = false;

			if (_.get(formData, 'partnerName', '')?.length <= 0) {
				error = true;
				setNameError(true);
			}
			if (_.get(formData, 'businessName', '')?.length <= 0) {
				error = true;
				setBusinessNameError(true);
			}
			if (
				_.get(formData, 'email', '')?.length <= 0 ||
				!regex.test(_.get(formData, 'email', ''))
			) {
				error = true;
				setEmailError(true);
			}
			if (
				_.get(formData, 'adminEmail', '')?.length <= 0 ||
				!regex.test(_.get(formData, 'adminEmail', ''))
			) {
				error = true;
				setAdminEmailError(true);
			}
			if (
				(_.get(formData, 'phoneNumber', '').length > 0 &&
					_.get(formData, 'phoneNumber', '')?.replace(/[^\d]/g, '').length < 10) ||
				_.get(formData, 'phoneNumber', '').length <= 0
			) {
				error = true;
				setContactNumberError(true);
			}

			formData.phoneNumber =
				_.get(formData, 'phoneNumber', '').length > 0
					? _.get(formData, 'phoneNumber', '')?.replace(/[^\d]/g, '')
					: '';
			if (_.get(formData, 'partnerAddress', '')?.length <= 0) {
				error = true;
				setPartnerAddressError(true);
			}
			if (_.get(formData, 'totalSpaces', '')?.length <= 0) {
				error = true;
				setTotalSpaceError(true);
			}
			if (
				parseInt(formData?.totalSpaces) <
				parseInt(formData?.residentSpaces) + parseInt(formData?.surplusSpaces)
			) {
				error = true;
				setLessTotalSpaceError(true);
			}
			if (_.get(formData, 'residentSpaces', '')?.length <= 0) {
				error = true;
				setResidentSpaceError(true);
			}
			if (_.get(formData, 'surplusSpaces', '')?.length <= 0) {
				error = true;
				setGuestSpaceError(true);
			}

			if (
				parseInt(formData?.residentSpaces) === 0 &&
				parseInt(formData?.surplusSpaces) === 0
			) {
				error = true;
				setResidentSpaceError(true);
			}

			if (!error) {
				const { data } = await api.updatePartner(formData);
				if (data?.success) {
					navigate('/partners');
					dispatch(setAlert(data?.message, 'Success'));
				} else {
					dispatch(setAlert(data?.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'partnerName') {
			setNameError(false);
		}
		if (name === 'businessName') {
			setBusinessNameError(false);
		}
		if (name === 'phoneNumber') {
			setContactNumberError(false);
		}
		if (name === 'email') {
			setEmailError(false);
		}
		if (name === 'adminEmail') {
			setAdminEmailError(false);
		}
		if (name === 'partnerAddress') {
			setPartnerAddressError(false);
		}
		if (name === 'residentSpaces') {
			setResidentSpaceError(false);
		}
		if (name === 'surplusSpaces') {
			setGuestSpaceError(false);
			if (formData?.residentSpaces) {
				setResidentSpaceError(false);
			}
		}
		if (name === 'totalSpaces') {
			setTotalSpaceError(false);
			setLessTotalSpaceError(false);
		}

		setFormData({ ...formData, [name]: value });
	};

	// const showImage = (id) => {
	// 	const e = document.getElementById(id);
	// 	if (e.style.display === 'none') {
	// 		e.style.display = 'block';
	// 	}
	// };
	// const hideImage = (id) => {
	// 	const e = document.getElementById(id);
	// 	if (e.style.display === 'block') {
	// 		e.style.display = 'none';
	// 	}
	// };

	// const onDrop = async (files) => {
	// 	var _URL = window.URL || window.webkitURL;

	// 	if (files.target.files[0] !== undefined) {
	// 		let logoImage = [];

	// 		Object.keys(files.target.files).forEach((file) => {
	// 			if (
	// 				files.target.files[file].type.split('/')[0] === 'image' &&
	// 				files.target.files[file].type.split('/')[1] !== 'gif'
	// 			) {
	// 				var img = new Image();
	// 				let reader = new FileReader();
	// 				reader.onloadend = async () => {
	// 					img.src = _URL.createObjectURL(files.target.files[file]);
	// 					if (files.target.files[file].type.split('/')[1] !== 'pdf') {
	// 						img.onload = async () => {
	// 							let name = files.target.files[file].name
	// 								? `${files.target.files[file].name
	// 										.replace(/-|\s/g, '-')
	// 										.trim()}`
	// 								: `${Math.floor(Math.random() * 100)}`;
	// 							files?.target?.name === 'partnerLogo' &&
	// 								logoImage.push({ name, imagePreviewUrl: reader.result });
	// 							files?.target?.name === 'partnerLogo' &&
	// 								setFormData({
	// 									...formData,
	// 									partnerLogo: [...logoImage],
	// 								});
	// 						};
	// 					} else {
	// 						let name = files.target.files[file].name
	// 							? `${files.target.files[file].name.replace(/-|\s/g, '-').trim()}`
	// 							: `${Math.floor(Math.random() * 100)}`;
	// 						files?.target?.name === 'partnerLogo' &&
	// 							logoImage.push({ name, imagePreviewUrl: reader.result });
	// 						files?.target?.name === 'partnerLogo' &&
	// 							setFormData({
	// 								...formData,
	// 								partnerLogo: [...logoImage],
	// 							});
	// 					}
	// 				};
	// 				reader.readAsDataURL(files.target.files[file]);
	// 			} else {
	// 				dispatch(setAlert('Invalid File Type', 'Error'));
	// 			}
	// 		});
	// 	}
	// };

	return (
		<PageWrapper title='Add partner'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='PersonAdd' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Create Partner
									</CardTitle>
								</CardLabel>
							</CardHeader>

							<CardBody>
								<div className='row'>
									<div className='create_vehicle_inner'>
										<div className='create-vehicle-wrapper add_vehicle'>
											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Partner name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='partnerName'
														className='form-control addVehicleSelect'
														autoComplete='off'
														value={_.get(formData, `partnerName`, '')}
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(event) => {
															if (!/^[a-zA-Z\s]+$/.test(event.key)) {
																event.preventDefault();
															}
														}}
														name='partnerName'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{nameError ? 'Partner Name is required!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Partner Contact Email{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														disabled
														id='email'
														className='form-control addVehicleSelect'
														autoComplete='off'
														value={_.get(formData, `email`, '')}
														onChange={(e) => {
															onChange(e);
														}}
														name='email'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{emailError ? 'Invalid Email!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Partner Contact Phone{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<InputMask
														guide={false}
														type='text'
														value={_.get(formData, `phoneNumber`, '')}
														mask={phoneNumberMask}
														className='form-control addVehicleSelect delete_form_input addpartner_withoutbtn'
														name='phoneNumber'
														onChange={(e) => onChange(e)}
													/>
												</div>
												<span className='text-danger danger-msg'>
													{contactNumberError
														? 'Enter a valid phone number!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Partner Address{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='partnerAddress'
														className='form-control addVehicleSelect'
														autoComplete='off'
														disabled
														value={_.get(
															formData,
															`partnerAddress`,
															'',
														)}
														onChange={(e) => {
															onChange(e);
														}}
														name='partnerAddress'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{partnerAddressError
														? 'Please enter Address!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Business Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='businessName'
														className='form-control addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														value={_.get(formData, `businessName`, '')}
														name='businessName'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{businessNameError
														? 'Please enter Business Name!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Admin Email{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='adminEmail'
														disabled
														className='form-control addVehicleSelect'
														autoComplete='off'
														value={_.get(formData, `adminEmail`, '')}
														onChange={(e) => {
															onChange(e);
														}}
														name='adminEmail'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{adminEmailError ? 'Invalid Email!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Resident Spaces{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='number'
														className='form-control addVehicleSelect'
														autoComplete='off'
														placeholder='#'
														value={_.get(
															formData,
															`residentSpaces`,
															'',
														)}
														onChange={(e) => {
															const value = e.target.value;
															if (
																value === '' ||
																parseInt(value, 10) >= 0
															) {
																onChange(e);
															} else {
																e.target.value = 0;
																onChange(e);
															}
														}}
														name='residentSpaces'
														min='0'
													/>
												</div>
												<span
													id='residentSpaces'
													className='text-danger danger-msg'
												>
													{residentSpaceError &&
													parseInt(formData?.residentSpaces) === 0 &&
													parseInt(formData?.surplusSpaces) === 0
														? 'Resident or Guest Spaces must be greater than zero'
														: residentSpaceError
														? 'Please enter Resident Spaces!'
														: null}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Guest Spaces{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='number'
														className='form-control addVehicleSelect'
														placeholder='#'
														autoComplete='off'
														value={_.get(formData, `surplusSpaces`, '')}
														onChange={(e) => {
															const value = e.target.value;
															if (
																value === '' ||
																parseInt(value, 10) >= 0
															) {
																onChange(e);
															} else {
																e.target.value = 0;
																onChange(e);
															}
														}}
														name='surplusSpaces'
														min='0'
													/>
												</div>

												<span
													id='surplusSpaces'
													className='text-danger danger-msg'
												>
													{guestSpaceError &&
														'Please enter Guest Spaces!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Total Spaces{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='number'
														className='form-control addVehicleSelect'
														autoComplete='off'
														placeholder='#'
														value={_.get(formData, `totalSpaces`, '')}
														onChange={(e) => {
															const value = e.target.value;
															if (
																value === '' ||
																parseInt(value, 10) >= 0
															) {
																onChange(e);
															} else {
																e.target.value = 0;
																onChange(e);
															}
														}}
														name='totalSpaces'
														min='0'
													/>
												</div>
												<span
													id='totalSpaces'
													className='text-danger danger-msg'
												>
													{totalSpaceError
														? 'Please enter Total Spaces!'
														: lessTotalSpaceError &&
														  'Total Spaces should be greater than sum of Resident Spaces and Guest Spaces!'}
												</span>
											</div>

											{/* <div className='card-info-item w-100 card-inner-partner'>
												<div className='data'>
													<div className=' d-flex  w-100 align-items-center'>
														<input
															type='checkbox'
															className='partnerCheckbox'
															checked={_.get(
																formData,
																`isAccessToRates`,
																false,
															)}
															onChange={(e) => {
																setFormData({
																	...formData,
																	isAccessToRates:
																		e.target.checked,
																});
															}}
															name='isAccessToRates'
														/>
														<span className='ps-2'>
															Access to Rates Tab
														</span>
													</div>
												</div>
											</div> */}

											<div className='w-100 d-flex flex-wrap'>
												<div className='partner_checkBoxes'>
													<div className='data'>
														<div className=' d-flex   align-items-center'>
															<input
																type='checkbox'
																className='partnerCheckbox'
																checked={_.get(
																	formData,
																	`isAccessToRates`,
																	false,
																)}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		isAccessToRates:
																			e.target.checked,
																	});
																}}
																name='isAccessToRates'
															/>
															<span className='ps-2'>
																Access to Rates Tab
															</span>
														</div>
													</div>
												</div>
												<div className='partner_checkBoxes'>
													<div className='data'>
														<div className=' d-flex  align-items-center'>
															<input
																type='checkbox'
																className='partnerCheckbox'
																checked={_.get(
																	formData,
																	`isAccessToRevenue`,
																	false,
																)}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		isAccessToRevenue:
																			e.target.checked,
																	});
																}}
																name='isAccessToRevenue'
															/>
															<span className='ps-2'>
																Access to Revenue Tab
															</span>
														</div>
													</div>
												</div>
												<div className='partner_checkBoxes'>
													<div className='data'>
														<div className=' d-flex  align-items-center'>
															<input
																type='checkbox'
																className='partnerCheckbox'
																checked={_.get(
																	formData,
																	`isAccessToReservation`,
																	false,
																)}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		isAccessToReservation:
																			e.target.checked,
																	});
																}}
																name='isAccessToReservation'
															/>
															<span className='ps-2'>
																Access to Reservation Tab
															</span>
														</div>
													</div>
												</div>
												<div className='partner_checkBoxes'>
													<div className='data'>
														<div className=' d-flex  w-100 align-items-center'>
															<input
																type='checkbox'
																className='partnerCheckbox'
																checked={_.get(
																	formData,
																	`sendAdminWelcomeEmail`,
																	false,
																)}
																disabled={isEmailSent}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		sendAdminWelcomeEmail:
																			e.target.checked,
																	});
																}}
																name='sendAdminWelcomeEmail'
															/>
															<span className='ps-2'>
																Send Admin Welcome Email
															</span>
														</div>
													</div>
												</div>
											</div>

											{/* <div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>Logo</p>
												</div>
												<div
													className='data'
													onMouseOver={() =>
														showImage('openPartnerLogoImage')
													}
													onMouseOut={() =>
														hideImage('openPartnerLogoImage')
													}
												>
													<input
														type='file'
														className='form-control addVehicleSelect choose_file'
														autoComplete='off'
														name='partnerLogo'
														accept='.png,'
														onChange={(e) => {
															onDrop(e);
														}}
													/>
													<div
														className='imageGrid'
														id='openPartnerLogoImage'
													>
														{formData?.partnerLogo &&
															formData?.partnerLogo?.map(
																(image, index) => {
																	if (
																		image?.name?.substring(
																			image?.name?.length - 3,
																		) !== 'pdf'
																	) {
																		return (
																			<React.Fragment
																				key={index}
																			>
																				<img
																					src={
																						image?.imagePreviewUrl
																					}
																					alt='documents'
																					style={{
																						width: '200px',
																						margin: '10px',
																					}}
																				/>
																				<div className='closeSpanDiv'>
																					<span
																						className='closeSpan'
																						onClick={() => {
																							formData?.partnerLogo?.splice(
																								index,
																								1,
																							);
																							setRefetch(
																								!refetch,
																							);
																							setFormData(
																								{
																									...formData,
																									partnerLogo:
																										formData?.partnerLogo,
																								},
																							);
																						}}
																					>
																						&times;
																					</span>
																				</div>
																			</React.Fragment>
																		);
																	} else {
																		<React.Fragment
																			key={index}
																		></React.Fragment>;
																	}
																},
															)}
													</div>
												</div>
												<span
													id='partnerLogoSpan'
													style={{
														position: 'absolute',
														opacity: '0',
														color: 'crimson',
													}}
												></span>
											</div> */}
										</div>
									</div>
								</div>

								<Button className='mx-2 mt-3' color='dark' onClick={updatePartner}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/partners')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default EditPartner;
